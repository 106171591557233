<template>
  <div v-loading="loader">
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1>Edit Card & Trophies</h1>
      <trophy-card-form
        :submit-button-text="'Update'"
        :card-trophy-data-to-edit="trophyCard"
        @editTrophyCard="editTrophyCard"
      />
    </div>
  </div>
</template>

<script>
import TrophyCardForm from "@/views/Pages/SuperAdmin/Cards&Trophies/TrophyCardForm";
import BackButton from "@/components/Router/BackButton"
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "EditTrophyCard",
  components: {TrophyCardForm, BackButton},
  data() {
    return {
      loader: false,
      trophyCardId: this.$route.params.id,
      trophyCard: {
        title: '',
        user_tier: 0,
        rule_type: 'food_log',
        days: 1,
        days_condition: 'in_row',
        surgery_day_type: 'day',
        surgery_days: 1,
        oldFrontImage: '',
        ad_front_image: '',
        oldBackImage: '',
        ad_back_image: '',
        link: '',
        notification_content: '',
      }
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  mounted() {
    this.getTrophyCard()
  },
  methods: {
    /**
     * Get trophy card
     */
    getTrophyCard() {
      let vm = this
      vm.loader = true
      const payload = {
        id: vm.trophyCardId
      }
      vm.$store.dispatch('CardsAndTrophiesModule/_getRequestedTrophyCard', payload)
        .then(response => {
          vm.trophyCard = response.data.data
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Trophy Card',
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    },

    editTrophyCard(event) {
      let vm = this
      vm.loader = true
      var formData = new FormData()
      formData.append('id', vm.trophyCardId)
      _.each(event, (value, key) => {
        formData.append(key, value)
      })
      const config = {headers: {'content-type': 'multipart/form-data'}}
      vm.$store.dispatch('CardsAndTrophiesModule/_updateTrophyCard', {config, formData})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Trophy Card Edited Successfully!'
          })
          vm.$router.back()
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Trophy Card',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    }
  }
}
</script>

<style scoped>

</style>
